import { SagaIterator } from 'redux-saga';
import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchCurrentContest } from './requests';

import { FetchCurrentContest } from './types';
import {
  getCurrentContestSuccess,
  getCurrentContestFailure
} from './action-creators';

function* explorersGrantsSaga(): SagaIterator {
  yield takeEvery('EXPLORERS_GRANTS/FETCH', getExplorersGrants);
}

function* getExplorersGrants(action: FetchCurrentContest): SagaIterator {
  const previewId = action.previewId;

  try {
    const response = yield call(fetchCurrentContest, previewId);
    yield put(getCurrentContestSuccess(response?.data?.data.currentContest));
  } catch (error: any) {
    yield put(getCurrentContestFailure(error?.response?.data?.error));
  }
}

export default explorersGrantsSaga;
