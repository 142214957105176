import { ExplorersGrantsState, CurrentContestActionType } from './types';

const initialState: ExplorersGrantsState = {
  id: 0,
  active: false,
  closeAt: '',
  ends: '',
  name: '',
  trips: [],
  loaded: false,
  error: null
};

const reducer = (
  state: ExplorersGrantsState = initialState,
  action: CurrentContestActionType
) => {
  switch (action.type) {
    case 'EXPLORERS_GRANTS/FETCH':
      return { ...state, loaded: false, error: null };
    case 'EXPLORERS_GRANTS/FETCH_SUCCESS':
      return {
        ...state,
        ...action.data,
        loaded: true
      };
    case 'EXPLORERS_GRANTS/FETCH_FAILURE':
      return { ...state, error: action.error, loaded: true };
    default:
      return state;
  }
};

export default reducer;
