// @flow

import { call, put, select, takeLatest } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import find from 'lodash/find';
import omit from 'lodash/omit';

import types from './action-types';
import * as actions from './action-creators';
import * as requests from './requests';
import * as cartWidgetActions from 'modules/cart-widget/action-creators';

import { events as analytics, trackAction } from 'modules/analytics';
import {
  fetchAlgoliaQueryInfo,
  trackAlgoliaEvent
} from 'modules/page/utils/analyticsHelpers';
import { setActiveBundle } from 'modules/bundles';

const getAlgoliaPayload = (
  price: string,
  discount: number,
  quantity: number,
  sku: string,
  index: string
) => {
  const productData = {
    queryID: fetchAlgoliaQueryInfo('queryID'),
    price: parseFloat(price.replace('$', '')),
    discount: discount || 0,
    quantity
  };
  return {
    eventName: analytics.PRODUCT_ADDED,
    objectIDs: [sku],
    index,
    objectData: [productData],
    currency: 'USD'
  };
};

function* fetch({ type, orderNumber, orderToken }): Saga<void> {
  try {
    let response;

    if (type === types.FETCH_CURRENT) {
      response = yield call(requests.fetchCurrent);
    } else {
      response = yield call(requests.fetchCart, orderNumber, orderToken);
    }

    yield put(actions.fetchSuccess(response.data));
  } catch (e) {
    const error = 'Failed to load the cart items.';
    yield put(actions.fetchFailure(error));
  }
}

function* addItem({ data }): Saga<void> {
  try {
    yield put(cartWidgetActions.open());
    const response = yield requests.populateCart(data);
    yield put(actions.addItemSuccess(response.data));

    const cartState = yield select((state) => state.cart);
    const algoliaIndex = yield select(
      (state) => state.session.config.algoliaTilesIndex
    );
    const item = find(cartState.items, (item) => {
      return data.variantId === item.variant.id;
    });

    const productData = {
      cart_id: cartState.orderNumber,
      product_id: item.product.sku,
      sku: item.product.sku,
      name: item.product.name,
      brand: item.product.brandName,
      product_url: item.product.url,
      price: item.variant.price,
      quantity: item.quantity,
      variant: item.variant.sku,
      value: item.variant.price,
      image_url: item.variant.cartImageUrl,
      placement: data.placement,
      currency: 'USD',
      group_id: item.product.productGroupId
    };

    trackAction(analytics.PRODUCT_ADDED, productData);

    const algoliaPayload = getAlgoliaPayload(
      item.product.price,
      item.product.discount,
      item.quantity,
      item.product.sku,
      algoliaIndex
    );

    trackAlgoliaEvent({
      type: 'addedToCartObjectIDsAfterSearch',
      payload: algoliaPayload
    });

    if (!response.data.fetchInProgress) {
      trackAction(analytics.CART_VIEWED, {
        cart_id: response.data.orderNumber,
        currency: 'USD',
        total: response.data.subtotal,
        products: response.data.items.map((item, index) => {
          return {
            product_id: item.variant.sku,
            sku: item.variant.sku,
            name: item.product.name,
            brand: item.product.brandName,
            url: item.product.url,
            price: item.variant.price,
            quantity: item.quantity,
            position: index + 1
          };
        })
      });
    }
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || 'Failed to add this item.';
    yield put(actions.addItemFailure(errorMessage));
  }
}

function* deleteItem({ id, placement }): Saga<void> {
  try {
    const cartState = yield select((state) => state.cart);
    const item = find(cartState.items, (item) => {
      return item.id === id;
    });

    const { data } = yield requests.updateCart(id, 0);

    trackAction(analytics.PRODUCT_REMOVED, {
      cart_id: cartState.orderNumber,
      product_id: item.product.sku,
      sku: item.product.sku,
      name: item.product.name,
      brand: item.product.brandName,
      product_url: item.product.url,
      price: item.variant.price,
      quantity: item.quantity,
      variant: item.variant.sku,
      placement,
      currency: 'USD'
    });

    yield put(actions.deleteItemSuccess(id, data));
    yield put(setActiveBundle());
  } catch (e) {
    const error = 'Failed to delete this item.';
    yield put(actions.deleteItemFailure(error, id));
  }
}

function* updateItem({ id, quantity, placement }): Saga<void> {
  const cartState = yield select((state) => state.cart);
  const item = find(cartState.items, (item) => {
    return item.id === id;
  });

  const eventName =
    item.quantity > quantity
      ? analytics.PRODUCT_REMOVED
      : analytics.PRODUCT_ADDED;

  let eventPayload = {
    cart_id: cartState.orderNumber,
    product_id: item.product.sku,
    sku: item.product.sku,
    name: item.product.name,
    brand: item.product.brandName,
    product_url: item.product.url,
    price: item.variant.price,
    quantity: item.quantity,
    variant: item.variant.sku,
    placement,
    value: item.variant.price,
    image_url: item.variant.cartImageUrl,
    currency: 'USD',
    group_id: item.product.productGroupId
  };

  if (eventName === analytics.PRODUCT_REMOVED) {
    eventPayload = omit(eventPayload, ['value', 'image_url', 'group_id']);
    trackAction(eventName, eventPayload);
  } else {
    // Product Added needs to be passed to Algolia

    const algoliaIndex = yield select(
      (state) => state.session.config.algoliaTilesIndex
    );

    const algoliaPayload = getAlgoliaPayload(
      item.product.price,
      item.product.discount,
      item.quantity,
      item.product.sku,
      algoliaIndex
    );

    trackAction(eventName, eventPayload);

    trackAlgoliaEvent({
      type: 'addedToCartObjectIDsAfterSearch',
      payload: algoliaPayload
    });
  }

  try {
    const { data } = yield requests.updateCart(id, quantity);
    yield put(actions.updateItemSuccess(id, quantity, data));
  } catch (error) {
    const errorMessage =
      error.response?.data?.error || 'Failed to update this item.';
    yield put(actions.updateItemFailure(errorMessage, id));
  }
}

function* applyCouponCode({ couponCode }): Saga<void> {
  const cartState = yield select((state) => state.cart);

  try {
    const response = yield requests.applyCouponCode(
      cartState.orderNumber,
      cartState.orderToken,
      couponCode
    );

    yield put(actions.applyCouponCodeSuccess(response.data));
  } catch (error) {
    const { response } = error;

    yield put(actions.applyCouponCodeFailure(response.data.error));
  }
}

export default function* cartSaga(): Saga<void> {
  yield takeLatest(types.FETCH_REQUEST, fetch);
  yield takeLatest(types.FETCH_CURRENT, fetch);
  yield takeLatest(types.ADD_ITEM_REQUEST, addItem);
  yield takeLatest(types.DELETE_ITEM_REQUEST, deleteItem);
  yield takeLatest(types.UPDATE_ITEM_REQUEST, updateItem);
  yield takeLatest(types.APPLY_COUPON_CODE_REQUEST, applyCouponCode);
}
