import React from 'react';

type Props = {
  width?: number;
  fill?: string;
};

const HuckberryLogoHeader = ({ width = 201, fill = '#1d2b39' }: Props) => (
  <svg
    width={`${width}px`}
    height="36px"
    viewBox={`0 0 ${width} 36`}
    role="img"
  >
    <span>Huckberry</span>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(0.000000, 4.000000)" fill={fill}>
        <path d="M34.7343,1.7623 C35.3133,3.4703 35.6093,5.2613 35.6093,7.0653 L35.6093,10.7123 L26.4523,10.7123 L26.4523,1.1973 L19.0753,1.1973 L19.2673,1.7603 C19.8513,3.4743 20.1493,5.2723 20.1493,7.0833 L20.1493,25.4913 L26.4523,25.4913 L26.4523,15.9533 L35.6093,15.9533 L35.6093,25.4913 L41.9123,25.4913 L41.9123,1.1973 L34.5423,1.1973 L34.7343,1.7623 Z" />
        <path d="M72.1459,9.8406 C74.0819,9.8406 75.0299,10.9126 75.3179,13.3746 L80.9199,13.0566 C80.6319,10.8326 80.1379,9.6816 78.8199,8.2526 C77.1309,6.4266 74.8649,5.5126 71.9399,5.5126 C65.9259,5.5126 61.8889,9.6416 61.8889,15.8366 C61.8889,21.8716 65.7609,25.8816 71.6099,25.8816 C74.9059,25.8816 77.4599,24.7696 79.1899,22.5466 C80.2199,21.2756 80.6319,20.2436 80.9199,18.1386 L75.3179,17.8216 C74.9469,20.1246 73.8759,21.1966 71.9809,21.1966 C69.5919,21.1966 68.6029,19.5686 68.6029,15.6376 C68.6029,11.6676 69.7159,9.8406 72.1459,9.8406" />
        <path d="M54.6796,16.9277 C54.6796,17.9627 54.7926,19.2747 54.1346,20.1497 C53.5286,20.9537 52.4986,21.4767 51.4926,21.4767 C49.9786,21.4767 49.3016,20.6697 49.3016,18.9417 L49.3016,5.8817 L43.6806,5.8817 L43.6836,18.9417 C43.6836,21.7067 43.9496,22.6757 44.7856,23.8657 C45.7816,25.2827 47.5986,25.7267 49.2296,25.8487 C51.3756,26.0087 54.0486,25.5847 55.3396,23.6567 C55.5486,24.4367 55.8026,25.3747 55.8396,25.4937 L60.4566,25.4937 L60.4566,5.8747 L54.6806,5.8967 L54.6796,16.9277 Z" />
        <path d="M101.7367,5.8899 L95.8867,5.8899 C95.8867,5.8899 88.5247,14.4159 88.1117,14.8919 L88.1117,1.1999 L81.3917,1.1999 L81.5887,1.7799 C82.1657,3.4829 82.4597,5.2699 82.4607,7.0679 L82.4687,25.4939 L88.1117,25.4939 L88.1117,20.4909 L91.6137,16.5999 L95.8567,25.4939 L102.4897,25.4939 L95.8567,12.5499 L101.7367,5.8899 Z" />
        <path d="M150.3578,9.5714 L150.3578,5.8884 L144.8688,5.8884 L144.8788,25.4934 L150.6878,25.4934 L150.6878,17.5124 C150.6878,13.4274 152.3668,11.2624 156.6098,11.2624 L156.6098,5.4824 C154.1458,5.4824 151.2188,6.8044 150.3578,9.5714" />
        <path d="M163.915,9.5714 L163.915,5.8884 L158.426,5.8884 L158.436,25.4934 L164.245,25.4934 L164.245,17.5124 C164.245,13.4274 165.924,11.2624 170.167,11.2624 L170.167,5.4824 C167.703,5.4824 164.777,6.8044 163.915,9.5714" />
        <path d="M185.0903,5.8883 L181.3503,17.0203 L177.6463,5.8883 L171.5443,5.8883 L178.6073,25.4983 L176.7823,31.1023 L181.4623,31.1023 C182.0023,29.5093 189.1083,8.5213 190.0003,5.8883 L185.0903,5.8883 Z" />
        <path d="M116.5873,16.8138 C116.5233,17.7618 116.3713,18.8908 115.9753,19.8418 C115.4973,20.9908 114.6653,21.8798 113.2053,21.8798 C111.3103,21.8798 110.1153,20.3318 110.1153,17.7508 L110.1153,14.5088 C110.1153,11.6898 111.3103,9.9028 113.2883,9.9028 C116.6943,9.9028 116.7513,14.3828 116.5873,16.8138 M115.1413,5.5208 C113.0163,5.5208 111.4333,6.3148 110.1153,8.3798 L110.1153,1.1998 L103.0583,1.1998 L103.2553,1.7828 C103.8313,3.4838 104.1263,5.2688 104.1273,7.0648 L104.1423,25.4928 L108.9093,25.4928 C108.9223,25.4698 109.2023,24.4318 109.4313,23.5798 C110.8283,25.3228 112.1643,25.8908 114.5243,25.8908 C119.6723,25.8908 122.7213,22.0788 122.7213,15.6068 C122.7213,9.4128 119.7963,5.5208 115.1413,5.5208" />
        <path d="M134.1166,9.2937 C136.2996,9.2937 137.2886,10.4847 137.5356,13.3037 L130.3676,13.3037 C130.6556,10.5637 131.8506,9.2937 134.1166,9.2937 Z M143.4266,16.6787 C143.3856,13.2247 142.9726,11.5967 141.7786,9.6507 C140.2546,7.1097 137.2056,5.5217 133.8286,5.5217 C128.0196,5.5217 124.0236,9.7697 124.0236,15.8847 C124.0236,21.8807 128.0196,25.8897 134.0336,25.8897 C136.9996,25.8897 139.1836,25.0567 140.9546,23.2307 C141.9596,22.1897 142.4946,21.3357 143.1046,19.6597 L137.7086,19.3127 C137.1886,20.9287 136.0166,21.8017 134.2406,21.8017 C131.8916,21.8017 130.3256,20.1337 130.3256,17.5927 C130.3256,17.4327 130.3256,16.9967 130.3676,16.6787 L143.4266,16.6787 Z" />
        <path d="M11.224,9.8094 L10.707,9.1724 L11.395,9.6184 C12.903,10.5954 14.426,11.4644 15.926,12.2034 C16.227,11.1594 16.428,10.0874 16.523,9.0154 C11.827,4.9154 9.419,1.1004 8.777,0.0004 C8.134,1.1004 5.726,4.9154 1.03,9.0154 C1.125,10.0874 1.326,11.1594 1.628,12.2034 C3.127,11.4644 4.65,10.5954 6.158,9.6184 L6.846,9.1724 L6.329,9.8094 C4.495,12.0684 2.367,14.1994 0,16.1424 C0.046,17.2724 0.206,18.4034 0.478,19.5044 C3.272,18.4684 5.728,17.2714 8.775,15.6164 L6.111,18.7784 L6.111,19.0074 C6.111,21.2124 5.748,23.4034 5.038,25.4904 L12.515,25.4904 C11.805,23.4034 11.442,21.2124 11.442,19.0074 L11.442,17.0224 C13.018,17.8274 14.691,18.5844 17.076,19.5044 C17.347,18.4034 17.507,17.2724 17.553,16.1424 C15.186,14.1994 13.057,12.0684 11.224,9.8094" />
      </g>
    </g>
  </svg>
);

export default HuckberryLogoHeader;
