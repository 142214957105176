import { CurrentContestType } from './types';

export const getCurrentContest = (previewId?: string) => ({
  type: 'EXPLORERS_GRANTS/FETCH',
  previewId
});

export const getCurrentContestSuccess = (data: CurrentContestType) => ({
  type: 'EXPLORERS_GRANTS/FETCH_SUCCESS',
  data
});

export const getCurrentContestFailure = (error: string) => ({
  type: 'EXPLORERS_GRANTS/FETCH_FAILURE',
  error
});
