import { SagaIterator } from 'redux-saga';
import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import difference from 'lodash/difference';

import { fetchBundles } from './requests';
import {
  getBundlesSuccess,
  getBundlesFailure,
  setActiveBundle,
  setActiveBundleSuccess
} from './action-creators';
import { CartItem } from 'modules/cart';

function* getBundlesSaga(): SagaIterator {
  try {
    const productIds = yield select((state) => {
      const inCartIds = state.cart.items.map(
        (item: CartItem) => item.product.id
      );

      const currentBundleDataIds = Object.keys(state.bundles.bundlesData).map(
        Number
      );

      return difference(inCartIds, currentBundleDataIds);
    });

    if (productIds.length < 1) {
      yield put(getBundlesSuccess({}));
      return;
    }

    const {
      data: { data }
    } = yield call(fetchBundles, productIds);

    yield put(getBundlesSuccess(data));

    yield put(setActiveBundle());
  } catch {
    yield put(getBundlesFailure());
  }
}

function* setActiveBundleSaga(): SagaIterator {
  try {
    const cartState = yield select((state) => state.cart);

    // This will actually point to the last item, but will appear as the first
    // since CSS is used to reverse the order
    const firstProductId =
      cartState.items[cartState.items.length - 1].product.id;

    yield put(setActiveBundleSuccess(firstProductId));
  } catch {
    yield put(getBundlesFailure());
  }
}

function* bundlesSaga(): SagaIterator {
  yield takeEvery('BUNDLES/GET', getBundlesSaga);
  yield takeLatest('BUNDLES/SET_ACTIVE_BUNDLE', setActiveBundleSaga);
}

export default bundlesSaga;
